/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

/*Banner, Carousel*/
import EntregasRap from '../assets/rap.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './banner.css';

/*Sucursales*/
import '../components/ubicaciones.css';
import Cedis from '../assets/cedis.jpg';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import '../components/mapa.css';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-icon.png";

/*Misión, Visión y Valores */
import '../components/cards.css';
import Vision from '../assets/vision.png';
import Mision from '../assets/mision.png';
import Valor from '../assets/valor.png';




export const Home = () => {
    let iconUbicacion = new L.icon({
        iconUrl: icon,
        iconShadow: iconShadow,
        iconSize: [40, 55], // size of the icon
        iconAnchor: [22, 94],
        shadowAnchor: [22, 94],
        popupAnchor: [-3, -76]
    })

    return (
        <><><>
            <section className='contenedor'>
                <div className='row'>
                    <div className='imgLogo'>
                        <img src={EntregasRap} ></img>
                    </div>
                    <div className='contentWrapper'>
                        <div className='content'>
                            <h2>Nuestra Historia</h2>
                            <p>Nenas Fruterías es una empresa 100% cachanilla y 100% familiar. Fue fundada en el año 1994 por el Sr. Raúl Medina y la Sra. Agustina Morales en Mexicali, Baja California, México. En realidad, esta historia da inicio en el año de 1985 cuando el señor Raúl Medina, junto a su carreta y su caballo, empezó a vender frutas y verduras en los alrededores de las colonias Pueblo Nuevo, Baja California y Loma Lindas. Como buen emprendedor, al paso de los años su caballo pasó a ser un pick up Chevrolet año 64 y fue así como amplió su venta a Villa Verde y Bugambilias. Como dice el dicho detrás de un buen hombre hay una gran mujer y el siempre fue de la mano de su esposa.</p>
                            <p>Gracias al gran equipo de trabajo que formaron en el año de 1991 se establecieron en un sobre ruedas.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='contenedor2'>
                <div className='cartas__textos'>
                    <h2>¿Dónde nos ubicamos?</h2>
                </div>
                <div className='sucursales'>
                    <div className='sucursales__fotos'>
                        <img src={Cedis} className='cedis' alt='#'></img>
                        <h1>Cedis Frutería Nena's</h1>
                        <p>Av. Río Santacruz S/N,</p><p>Fracc. Hacienda Bilbao</p>
                    </div>
                </div>
            </section>
            <section className='contenedorMapa'>
                <div className='container bg-transparent'>
                    <MapContainer center={[32.616604, -115.398977]} zoom={16} scrollWheelZoom={false} className='mapa'>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[32.616604, -115.398977]} icon={iconUbicacion}>
                            <Popup>
                                Cedis Nena's Fruteria.
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </section>
        </>
            <section className='contenedor4'>
                <div className='cartas__textos1'>
                    <h2>Misión, Visión y Valores para Entregas Rap</h2>
                </div>
                <div className="container bg-transparent">
                    <div className="card2">
                        <div className="left-column background1-left-column">
                            <h6>Entregas Rap</h6>
                            <img src={Mision} className='mision'></img>
                        </div>
                        <div className="right-column2">
                            <h2>Misión</h2>
                            <p>Ofrecer el servicio de logística en transporte de mercancías, al menor costo y mejor calidad a la competencia.</p>
                        </div>
                    </div>
                    <div className="card2">
                        <div className="left-column background2-left-column">
                            <h6>Entregas Rap</h6>
                            <img src={Vision} className='vision'></img>
                        </div>
                        <div className="right-column2">
                            <h2>Visión</h2>
                            <p>Ser líderes en el servicio de logística, reduciendo tiempos, riesgos y costos ofreciendo a nuestros clientes seguridad, calidad y cumplimiento en el servicio.</p>
                        </div>
                    </div>
                </div>
                <div className='container bg-transparent'>
                    <div className="card2">
                        <div className="left-column background3-left-column">
                            <h6>Entregas Rap</h6>
                            <img src={Valor} className='valores'></img>
                        </div>
                        <div className="right-column2">
                            <h2>Valores</h2>
                            <p><b>Nuestros valores</b> nos distinguen ya que no se limitan a nuestros clientes y colaboradores, si no a nuestro entorno social.</p>
                            <li><b>Lealtad</b> ante nuestros trabajadores, clientes y proveedores.</li>
                            <li><b>Congruencia</b> entre lo que decimos y hacemos.</li>
                            <li>Búsqueda permanente del <b>buen ambiente laboral</b> y el arraigo de nuestros trabajadores</li>
                            <li><b>Enfoque</b> a la obtención de <b>resultados</b> positivos cada vez más satisfactorios para el empleado y para los accionistas de la empresa.</li>
                            <li><b>Disciplina</b> de todo el personal para el cumplimiento de las políticas establecidas y para una superación permanente..</li>
                            <li><b>Orientación al cliente</b> para dar el servicio que requiere y el trato que le corresponde.</li>
                            <li><b>Trabajo en equipo</b> de las personas que laboran en la empresa, para lograr los objetivos fijados.</li>
                        </div>
                    </div>
                </div>
            </section>
        </>
        </>
    )
}
