import React from 'react'
import '../components/transportes.css';
/*Bayas*/
import Baya1 from '../assets/bayas/NISSANNP30020011.png'
/*Troques*/
import Troque1 from '../assets/troques/CHEVROLETSILVERADO12.png'
import Troque2 from '../assets/troques/FREIGHTLINER1.png'
import Troque3 from '../assets/troques/INTERNATIONALC430012.png'
import Troque4 from '../assets/troques/GMC7500.png'
/*Trailers*/
import Trailer1 from '../assets/trailers/VOLVO06443RABANITO.png'
import Trailer2 from '../assets/trailers/INT.C4700DURASAR20091.png'
import Trailer3 from '../assets/trailers/TRAILERINTERNATIONAL.png'
import Trailer4 from '../assets/trailers/KENWORTHT300COMANDO2007.png'
/*Pick-Up*/
import Pickup1 from '../assets/pick-up/CHEVROLETS10.png'
import Pickup2 from '../assets/pick-up/NISSANNP300201.png'
import Pickup3 from '../assets/pick-up/PICK-UPFORDF150.png'
import Pickup4 from '../assets/pick-up/PICK-UPFORDRANGER.png'
/*Panel*/
import Panel1 from '../assets/panel/PANELFORDECONOLINEVAN2005.png'
import Panel2 from '../assets/panel/PANELGDE.FORDE35VAN2002.png'
import Panel3 from '../assets/panel/PANELTRANSITFORDTRANSIT LT2011.png'
import Panel4 from '../assets/panel/PANELCHEV.EXPRESS VAN2007.png'
/*Camion*/
import Camion1 from '../assets/camion/CAMIONF350PASAJEROSROJO.png'
import Camion2 from '../assets/camion/CAMIONINTERNATIONAL15.png'
import Camion3 from '../assets/camion/CAMIONINTERNATIONAL18.png'


export const Transportes = () => {
  return (
    <section className='contenedorTransportes'>
        <div>
            <h1>Transportes</h1>
        </div>
        <div className='troques'>
            <h1>Bayas</h1>
        </div>
        <div className='container__cards'>
          <div className='card3'>
            <div className='cover'>
              <img src={Baya1} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Nissan NP 300</h2>
            </div>
          </div>
        </div>

        <div className='troques'>
            <h1>Troques</h1>
        </div>
        <div className='container__cards'>
          <div className='card3'>
            <div className='cover'>
              <img src={Troque1} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Chevrolet Silverado</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Troque2} className='modelos1' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Freightliner</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Troque3} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>International C4300</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Troque4} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>GMC 7500</h2>
            </div>
          </div>
        </div>
        
        <div className='troques'>
            <h1>Trailers</h1>
        </div>
        <div className='container__cards'>
          <div className='card3'>
            <div className='cover'>
              <img src={Trailer1} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Volvo 06443</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Trailer2} className='modelos1' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Volvo 05547</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Trailer3} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>International</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Trailer4} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Kenworth  Comando</h2>
            </div>
          </div>
        </div>

        <div className='troques'>
            <h1>Pick-Up</h1>
        </div>
        <div className='container__cards'>
          <div className='card3'>
            <div className='cover'>
              <img src={Pickup1} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Chevrolet S10</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Pickup2} className='modelos1' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Nissan NP300</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Pickup3} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Ford F150</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Pickup4} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Ford Ranger</h2>
            </div>
          </div>
        </div>

        <div className='troques'>
            <h1>Panel</h1>
        </div>
        <div className='container__cards'>
          <div className='card3'>
            <div className='cover'>
              <img src={Panel1} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>  Ford Econoline Van</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Panel2} className='modelos1' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Ford E350 Van</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Panel3} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Ford Transit LT</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Panel4} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>Chev. Express Van</h2>
            </div>
          </div>
        </div>

        <div className='troques'>
            <h1>Camiones</h1>
        </div>
        <div className='container__cards'>
          <div className='card3'>
            <div className='cover'>
              <img src={Camion1} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>F350 Pasajero</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Camion2} className='modelos1' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>International #15</h2>
            </div>
          </div>
          <div className='card3'>
            <div className='cover'>
              <img src={Camion3} className='modelos' alt=''/>
              <div className='img__back'></div>
            </div>
            <div className='description'>
              <h2>International #18</h2>
            </div>
          </div>
        </div>
    </section>
  )
}
